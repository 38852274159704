import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { persistStore, persistReducer } from 'redux-persist';
// import rootReducer, { rootPersistConfig } from './rootReducer';
// import { combineReducers } from 'redux';
// import storage from 'redux-persist/lib/storage';
import mapReducer from '../features/map/mapSlice';
import { mapApi } from '../features/map/mapApi';


export const store = configureStore({
  reducer: {
    map: mapReducer,
    [mapApi.reducerPath]: mapApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    // getDefaultMiddleware().concat(mediaUserApi.middleware);
    return getDefaultMiddleware()
      .concat(mapApi.middleware)
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
