import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store'

const accessToken = 'pk.eyJ1IjoiYWJzdHJvc3MiLCJhIjoiY2t3amV3YmRpMWhoZTJ2cXRzODk2c3YyayJ9.M-kQwYcHV4ZOIn5Tqhb3cA'; // Public Token

export interface MapState {
  mapStyle: string;
  viewState: {
    latitude: number,
    longitude: number,
    zoom: number
  };
  status: 'idle' | 'intializing' | 'loading' | 'failed';
  accessToken: string;
}

const initialState = {
    mapStyle: 'mapbox://styles/mapbox/streets-v11',
    viewState: {
      latitude: 30.2672,
      longitude: -97.7431,
      zoom: 15
    },
  status: 'idle',
  accessToken
} as MapState;

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setViewState: (state, action: PayloadAction<any>) => {
      state.viewState = action.payload;
    },
  },
})

export const { setViewState } = mapSlice.actions;

export const selectedMap = (state: RootState) => state.map;

export default mapSlice.reducer;
