import * as React from 'react';
import Map from 'react-map-gl';

import {useCallback} from 'react';
import { 
  useAppDispatch, 
  useAppSelector 
} from '../../app/hooks';
import {
  selectedMap, setViewState
} from './mapSlice'

import 'mapbox-gl/dist/mapbox-gl.css';

import { MAP_API } from '../../config-global'

export default function MapView() {
  const  { mapStyle, viewState } = useAppSelector(selectedMap);
  const dispatch = useAppDispatch();

  const onMove = useCallback((evt: { viewState: any; })  => {
    dispatch(setViewState(evt.viewState));
  }, [dispatch]);

  return (
    <Map
      {...viewState}
      onMove={onMove}
      style={{width: 800, height: 600}}
      mapStyle={mapStyle}
      mapboxAccessToken={MAP_API}
    />
  );
}