import React from 'react';
import Controls from './features/map/controls'
import Map from './features/map/Map'
import './App.css';

function App() {
  return (
    <div>
      <Controls />
      <Map />
    </div>
  );
}

export default App;
